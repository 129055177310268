import { Group, TextInput, Select, Button, useComputedColorScheme } from "@mantine/core";
import { IconChevronDown, IconChevronUp, IconX } from "@tabler/icons-react";
import { fieldTypes } from "../helpers";

const FieldGroup = ({ field, onChange, position, onReorder, groupSize }) => {
    const computedColorScheme = useComputedColorScheme('light', { getInitialValueInEffect: true });

    console.log(position)

    return (
        <Group className="tif-mb-15" wrap="nowrap">
            <Button.Group orientation="vertical">
                <Button variant="default" size="xs" disabled={position === 0} onClick={() => onReorder(position, position - 1)}><IconChevronUp size={12} /></Button>
                <Button variant="default" size="xs" disabled={position + 1 === groupSize} onClick={() => onReorder(position, position + 1)}><IconChevronDown size={12} /></Button>
            </Button.Group>
            <TextInput
                placeholder="Field Name"
                className="flex-grow-1"
                value={field.name}
                onChange={e => onChange(prevState => prevState.map((f, i) => i === position ? { ...f, name: e.target.value } : f))}
            />
            <Select
                placeholder="Field Type"
                data={fieldTypes}
                className="flex-grow-1"
                value={field.type}
                onChange={value => onChange(prevState => prevState.map((f, i) => i === position ? { ...f, type: value } : f))}
                searchable
            />
            <Button variant="transparent" color="white" px="0" onClick={() => onChange(prevState => prevState.filter((_, i) => i !== position))}><IconX color={computedColorScheme === "dark" ? "white" : "black"} size={14} /></Button>
        </Group>
    );
};

export default FieldGroup;