import React from 'react';
import "./styles/app.scss";
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import RouteAuthorizer from './views/components/RouteAuthorizer';
import { UserStateProvider } from './views/components/UserStateContext';
import { createTheme, MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';

// Styles
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/charts/styles.css';

// Pages
import Login from './pages/Login';
import Home from './pages/Home';
import ResetPassword from './pages/reset-password/ResetPassword';
import NewPassword from './pages/reset-password/NewPassword';
import UserAccounts from './pages/UserAccounts';
import Register from './pages/Register';
import TempoAuth from './pages/TempoAuth';
import Clients from './pages/clients/Clients';
import TeamDashboard from './pages/TeamDashboard';
import SingleClient from './pages/clients/SingleClient';
import Profile from './pages/Profile';

if (window.location.protocol === 'http:' && window.location.hostname !== 'localhost') {
  window.location.href = window.location.href.replace('http:', 'https:');
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <RouteAuthorizer element={ <Login /> } />,
  },
  {
    path: "/register/:key",
    element: <RouteAuthorizer element={ <Register /> } />,
  },
  {
    path: "/reset-password",
    element: <RouteAuthorizer element={ <ResetPassword /> } />,
  },
  {
    path: "/reset-password/:key",
    element: <RouteAuthorizer element={ <NewPassword /> } />,
  },
  {
    path: "/home",
    element: <RouteAuthorizer element={ <Home /> } requireAuth />,
  },
  {
    path: "/user-accounts",
    element: <RouteAuthorizer element={ <UserAccounts /> } requireAuth admin />,
  },
  {
    path: "/tempo-auth",
    element: <RouteAuthorizer element={ <TempoAuth /> } excludeAuth />,
  },
  {
    path: "/team-dashboard",
    element: <RouteAuthorizer element={ <TeamDashboard /> } requireAuth />,
  },
  {
    path: "/clients",
    element: <RouteAuthorizer element={ <Clients /> } requireAuth />,
  },
  {
    path: "/clients/:id",
    element: <RouteAuthorizer element={ <SingleClient /> } requireAuth />,
  },
  {
    path: "/:username",
    element: <RouteAuthorizer element={ <Profile /> } requireAuth />,
  },
]);

const theme = createTheme();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MantineProvider theme={theme} defaultColorScheme="dark">
    <Notifications />
    <UserStateProvider>
      <RouterProvider router={ router } />
    </UserStateProvider>
  </MantineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
