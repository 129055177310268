import { useEffect, useState } from 'react';
import { Avatar, Table, Group, Text, rem, Skeleton, Input, CloseButton, Button, Popover, TextInput, Select, Tabs, FloatingIndicator, SimpleGrid, Paper } from '@mantine/core';
import {
    IconChevronRight,
    IconSearch,
    IconPlus
} from '@tabler/icons-react';
import { getAllClients, createClient } from '../../data/dataApi';
import { useUserState } from '../../views/components/UserStateContext';
import ContentWrapper from '../../views/components/ContentWrapper';
import { useNavigate } from 'react-router';
import { projectTypes, showSuccessNotification, showUnexpectedErrorNotification } from '../../views/helpers';
import ViewModeControl from '../../views/components/ViewModeControl';
import { Link } from 'react-router-dom';

const Clients = () => {
    const { clients, setClients } = useUserState();
    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState(clients);
    const [newClientName, setNewClientName] = useState('');
    const [newClientNameError, setNewClientNameError] = useState('');
    const [newClientKey, setNewClientKey] = useState('');
    const [newClientKeyError, setNewClientKeyError] = useState('');
    const [newClientType, setNewClientType] = useState('');
    const [newClientTypeError, setNewClientTypeError] = useState('');
    const [newClientLoading, setNewClientLoading] = useState(false);
    const [rootRef, setRootRef] = useState(null);
    const [tab, setTab] = useState('All');
    const [controlsRefs, setControlsRefs] = useState({});
    const [viewMode, setViewMode] = useState(localStorage.getItem('clients_view_mode') || 'list-view');
    const setControlRef = val => node => {
        controlsRefs[val] = node;
        setControlsRefs(controlsRefs);
    };

    const navigate = useNavigate();

    const handleCreateClient = async (name, key, type) => {
        setNewClientNameError('');
        setNewClientKeyError('');
        setNewClientTypeError('');
        setNewClientLoading(true);

        if (!name) {
            setNewClientNameError('Name is required');
        }

        if (!key) {
            setNewClientKeyError('Key is required');
        }

        if (!type) {
            setNewClientTypeError('Type is required');
        }

        if (name && key && type) {
            const response = await createClient(name, key, type);

            if (response.success) {
                const refreshClients = prevClients => [...prevClients, response.data.rows[0]].sort((a, b) => a.name.localeCompare(b.name));
                setClients(prevState => refreshClients(prevState));
                setSearchResults(prevState => refreshClients(prevState));
                showSuccessNotification('Client created successfully');
                navigate(`/clients/${response.data.rows[0].id}`);
            } else {
                showUnexpectedErrorNotification();
            }
        }

        setNewClientLoading(false);
    };

    useEffect(() => {
        (async () => {
            const clientsResponse = await getAllClients();

            if (clientsResponse.success) {
                const allClients = clientsResponse.data.rows.sort((a, b) => a.name.localeCompare(b.name));
                setClients(allClients);
                console.log(allClients)
                setSearchResults(allClients);
            }
        })();
    }, []);

    useEffect(() => {
        if (search) {
            setSearchResults(clients.filter(client => client.name.toLowerCase().includes(search.toLowerCase())));
        } else {
            setSearchResults(clients);
        }
    }, [search]);

    const filterClients = filter => searchResults.filter(client => {
        if (filter === 'All') {
            return !client.archived;
        } else if(filter === 'Archived') {
            return client.archived;
        }

        return client.type === filter && !client.archived;
    });

    const ClientsTable = ({ filter }) => {
        const filteredClients = filterClients(filter);

        return (
            <Table verticalSpacing="md">
                <Table.Tbody>
                    {
                        filteredClients.map(client => (
                            <Table.Tr key={client.name} role="button">
                                <Table.Td onClick={() => navigate(`/clients/${client.id}`)}>
                                    <Group gap="md">
                                        <Avatar size={40} src={client.avatar_url} radius={40} />
                                        <div>
                                            <Text fz="sm" fw={500}>
                                                {client.name}
                                            </Text>
                                            <Text c="dimmed" fz="xs">
                                                {client.type}
                                            </Text>
                                        </div>
                                        {
                                            client.type === "Project" || client.type === "Maintenance" ?
                                            <>
                                                {
                                                    client.total_budget ?
                                                        <Paper p="sm">
                                                            <Text size="11px" mb={2} c="dimmed" className="title">
                                                                Budget
                                                            </Text>
                                                            <Text size="sm">
                                                                {client.total_budget}
                                                            </Text>
                                                        </Paper>
                                                    :
                                                        null
                                                }
                                                {
                                                    client.logged_time ?
                                                        <Paper p="sm">
                                                            <Text size="11px" mb={2} c="dimmed" className="title">
                                                                Logged
                                                            </Text>
                                                            <Text size="sm">
                                                                {(client.logged_time / 3600).toFixed(2)}
                                                            </Text>
                                                        </Paper>
                                                    :
                                                        null
                                                }
                                                {
                                                    (client.total_budget && client.billed_time) ?
                                                        <Paper p="sm">
                                                            <Text size="11px" mb={2} c="dimmed" className="title">
                                                                Remaining
                                                            </Text>
                                                            <Text size="sm">
                                                                {(client.total_budget - (client.billed_time / 3600)).toFixed(1)}
                                                            </Text>
                                                        </Paper>
                                                    :
                                                        null
                                                }
                                            </>
                                            :
                                                null
                                        }
                                        <div className="tif-ml-auto">
                                            <IconChevronRight style={{ width: rem(14), height: rem(14) }} stroke={1.5} />
                                        </div>
                                    </Group>
                                </Table.Td>
                            </Table.Tr>
                        ))
                    }
                </Table.Tbody>
            </Table>
        );
    };

    const ClientsGrid = ({ filter }) => {
        const filteredClients = filterClients(filter);

        return (
            <SimpleGrid cols={{ base: 1, md: 4 }}>
                {
                    filteredClients.map((client, index) => (
                        <Paper radius="md" withBorder p="lg" bg="var(--mantine-color-body)" className="text-center" key={index}>
                            <Avatar
                                src={client.avatar_url}
                                size={120}
                                radius={120}
                                mx="auto"
                            />
                            <Text ta="center" fz="lg" fw={500} mt="md">
                                {client.name}
                            </Text>
                            <Text ta="center" c="dimmed" fz="sm" mb="20px">
                                {client.type}
                            </Text>

                            <Link to={`/clients/${client.id}`} className="btn-anchor">View Client</Link>
                        </Paper>
                    ))
                }
            </SimpleGrid>
        );
    };

    return (
        <ContentWrapper className="scrollable">
            <div id="clients" className="wrapper">
                {
                    searchResults !== null ?
                        <>
                            <Group align="center" justify="space-between" mb="15px">
                                <Text size="sm" className="tif-ls-50 text-uppercase">
                                    Clients
                                </Text>
                                <Popover width={400} trapFocus position="bottom" withArrow shadow="md" closeOnClickOutside={false}>
                                    <Popover.Target>
                                        <Button leftSection={<IconPlus size={16} />}>Add Client</Button>
                                    </Popover.Target>
                                    <Popover.Dropdown>
                                        <TextInput label="Name" placeholder="Name" mb="md" value={newClientName} onChange={e => setNewClientName(e.currentTarget.value)} error={newClientNameError} />
                                        <TextInput label="Account Key" placeholder="Account Key" mb="md" value={newClientKey} onChange={e => setNewClientKey(e.currentTarget.value)} error={newClientKeyError} />
                                        <Select label="Type" placeholder="Client Type" data={projectTypes} mb="md" value={newClientType} onChange={setNewClientType} error={newClientTypeError} />
                                        <Button variant="outline" onClick={() => handleCreateClient(newClientName, newClientKey, newClientType)} loading={newClientLoading}>Save</Button>
                                    </Popover.Dropdown>
                                </Popover>
                            </Group>
                            <Input
                                className="search-input"
                                placeholder={`Search ${tab} Clients`}
                                leftSection={<IconSearch size={24} />}
                                value={search}
                                onChange={e => setSearch(e.currentTarget.value)}
                                rightSectionPointerEvents="all"
                                mt="md"
                                mb="md"
                                variant="filled"
                                size="xl"
                                rightSection={
                                    <CloseButton
                                        aria-label="Search Clients"
                                        onClick={() => setSearch('')}
                                        style={{ display: search ? undefined : 'none' }}
                                        size="xl"
                                    />
                                }
                            />
                            <Group align="center" mb="15px">
                                <Tabs variant="none" value={tab} onChange={setTab} className="flex-grow-1">
                                    <Tabs.List ref={setRootRef} className="list">
                                        <Tabs.Tab value="All" ref={setControlRef('All')} className="tab">
                                            All
                                        </Tabs.Tab>
                                        <Tabs.Tab value="Project" ref={setControlRef('Project')} className="tab">
                                            Project
                                        </Tabs.Tab>
                                        <Tabs.Tab value="Targets" ref={setControlRef('Targets')} className="tab">
                                            Targets
                                        </Tabs.Tab>
                                        <Tabs.Tab value="Maintenance" ref={setControlRef('Maintenance')} className="tab">
                                            Maintenance
                                        </Tabs.Tab>
                                        <Tabs.Tab value="Ad Hoc" ref={setControlRef('Ad Hoc')} className="tab">
                                            Ad Hoc
                                        </Tabs.Tab>
                                        <Tabs.Tab value="Archived" ml="auto" ref={setControlRef('Archived')} className="tab">
                                            Archived
                                        </Tabs.Tab>

                                        <FloatingIndicator
                                            target={tab ? controlsRefs[tab] : null}
                                            parent={rootRef}
                                            className="indicator"
                                        />
                                    </Tabs.List>
                                </Tabs>
                                <ViewModeControl viewMode={viewMode} onChange={setViewMode} id="clients" />
                            </Group>
                            {
                                viewMode === "list-view" ?
                                    <ClientsTable filter={tab} />
                                :
                                    <ClientsGrid filter={tab} />
                            }
                        </>
                        :
                        <>
                            <Skeleton height={20} width={120} radius="md" className="tif-mb-15" />
                            <Skeleton height={805} radius="md" />
                        </>
                }
            </div>
        </ContentWrapper>
    );
};

export default Clients;
