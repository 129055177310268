import { useEffect, useState } from 'react';
import { Modal, Box, Group } from '@mantine/core';
import { clientsSettingsLinks } from '../helpers';
import DefaultFieldGroups from './DefaultFieldGroups';
import { useUserState } from './UserStateContext';

const ClientsSettingsModal = ({ opened, close }) => {
    const { globalDefaultFieldGroups, setGlobalDefaultFieldGroups, projectDefaultFieldGroups, setProjectDefaultFieldGroups, targetsDefaultFieldGroups, setTargetsDefaultFieldGroups, maintenanceDefaultFieldGroups, setMaintenanceDefaultFieldGroups, adHocDefaultFieldGroups, setAdHocDefaultFieldGroups, globalEditingMode, setGlobalEditingMode } = useUserState();
    const initialTab = clientsSettingsLinks[0];

    const [activeTab, setActiveTab] = useState(initialTab);

    const items = clientsSettingsLinks.map((item) => (
        <Box
            component="a"
            onClick={() => setActiveTab(item)}
            key={item.label}
            className={`link position-relative ${activeTab.link === item.link && "linkActive"}${ item.order > 1 ? " sublink" : "" }`}
            style={{ paddingLeft: `calc(${item?.order || 1} * var(--mantine-spacing-md))` }}
            mb="5px"
        >
            {item.label}
        </Box>
    ));

    const handleModalClose = () => {
        if(!globalEditingMode) {
            setGlobalEditingMode(false);
            close();
        } else {
            alert("Please save or cancel your changes before closing the modal.");
        }
    };

    return (
        <Modal.Root opened={opened} onClose={handleModalClose} className="clients-settings-modal" size="xl">
            <Modal.Overlay blur="3" />
            <Modal.Content>
                <Group gap="25px" align="stretch" className="h-100" wrap="nowrap">
                    <div className="client-settings-tabs">
                        <Box pl="16px" py="16px">
                            {items}
                        </Box>
                    </div>
                    <Modal.Body className="d-flex flex-column flex-grow-1 tif-pl-0">
                        <Modal.Header className="tif-px-0">
                            <Modal.Title>{activeTab.label}</Modal.Title>
                            <Modal.CloseButton />
                        </Modal.Header>
                        {
                            activeTab.link === "#global-field-groups" ?
                                <DefaultFieldGroups groups={globalDefaultFieldGroups} onChange={setGlobalDefaultFieldGroups} />
                            :
                                <></>
                        }

                        {
                            (activeTab.link === "#project-field-groups") ?
                                <DefaultFieldGroups groups={projectDefaultFieldGroups} onChange={setProjectDefaultFieldGroups} type="Project" />
                            :
                                <></>
                        }

                        {
                            (activeTab.link === "#targets-field-groups") ?
                                <DefaultFieldGroups groups={targetsDefaultFieldGroups} onChange={setTargetsDefaultFieldGroups} type="Targets" />
                            :
                                <></>
                        }

                        {
                            (activeTab.link === "#maintenance-field-groups") ?
                                <DefaultFieldGroups groups={maintenanceDefaultFieldGroups} onChange={setMaintenanceDefaultFieldGroups} type="Maintenance" />
                            :
                                <></>
                        }

                        {
                            (activeTab.link === "#ad-hoc-field-groups") ?
                                <DefaultFieldGroups groups={adHocDefaultFieldGroups} onChange={setAdHocDefaultFieldGroups} type="Ad Hoc" />
                            :
                                <></>
                        }
                    </Modal.Body>
                </Group>
            </Modal.Content>
        </Modal.Root>
    );
};

export default ClientsSettingsModal;